.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.page-footer {
  background: #000;
  color: white !important;
  
  left: 0px;
  right: 0;
  text-align: center;
  padding: 7px;
  color: #fff;
  font-size: 14px;
  border-top: 1px solid red;
  z-index: 3;
}
