/* body {
  overflow-x: hidden !important;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.lenk {
  margin-right: 20px;
  color: white;
}

.lenk:hover {
  color: white;
}

.navbar-toggler {
  background-color: #fe0000 !important;
}
.navbar-toggler span{
  color: white;
}

.cardSlid {
  background: #5a3613 !important;
  color: white !important;
  border: none !important;
}

.jashja {
  border: 1px solid #fe0000 !important;
  padding: 20px 10px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.jashja:hover {
  box-shadow: inset 0px -1px 12px 3px #fe0000 !important;
}

@media screen and (max-width: 850px) {
  .btn-Green {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .jsjdha {
    padding-left: 25px !important;
    padding-right: 25px !important;
    margin-right: 10px;
  }
}

.loginpage .card {
  box-shadow: 0px 7px 12px 3px #000000 !important; 
  background: linear-gradient(45deg, #6c3a9d, #136496);
    border: 1px solid white !important;
}
