.adjustmentsection .card {
  border: 1px solid rgb(224, 229, 51);
  transition: all 0.3s ease-in-out;
}

.adjustmentsection .card.innercard {
    background-color: rgb(229 184 11);
}

.adjustmentsection .card {
    border: 1px solid rgb(229 184 11);
    transition: all 0.3s ease-in-out;
}